import Vue from "vue";
import BigNumber from "bignumber.js";
import { formatAccount } from "@/utils/format";
import { baseURL } from "@/utils/config";


export function setRPCNode (url) {
  let rpcNode = {
    url: url,
    expiration: new Date().getTime() + 12 * 60 * 60 * 1000
  }
  let rpcSetting = localStorage.getItem('rpc-setting') || '{}'
  rpcSetting = JSON.parse(rpcSetting)

  rpcSetting[state.chainKey] = rpcNode

  localStorage.setItem('rpc-setting', JSON.stringify(rpcSetting))
  console.log('rpc:::', url)
}

export function deleteCurrentRpcNode () {
  let rpcSetting = localStorage.getItem('rpc-setting') || '{}'
  rpcSetting = JSON.parse(rpcSetting)
  delete rpcSetting[state.chainKey]
  localStorage.setItem('rpc-setting', JSON.stringify(rpcSetting))
}

function loadCartList (chain) {
  const val = window.localStorage.getItem(`nft_${chain}_cart`);
  return val && JSON.parse(val) || []
}

function loadCartType () {
  return window.localStorage.getItem(`nft_cart_mode`) || 'buy';
}

export const state = Vue.observable({
  default_account: "",
  default_name: "",
  display_account: "",
  chainKey: localStorage.getItem("chainKey") || "eth",
  chainId: "",
  walletKey: "",
  walletIcon: "",
  walletName: "",
  walletPath: "",

  walletLogo: () =>
    baseURL +
    "/logos/" +
    (state.chainKey === "eth" ? "" : state.chainKey + "/"),
  layer2Account: null,
  layer2Info: null,
  layer2Markets: null,
  layer2Tokens: null,
  layer2AccountId: 0,
  strategyId: "",
  priceScale: "",
  volumeScale: "",
  stage: 0,
  reloadReward: false,
  tolerance: "1",
  partialFill: false,

  tokenMap: {},
  routePath: {
    from: "",
    to: "",
    parts: 0,
    routes: [],
  },
  unexchangeList: {},
  exchangeList: [],
  favoriteList: [],
  gasPrice: "5000000000",
  gasPriceOptions: [],
  autoSlippage: window.localStorage.getItem("autoSlippage") == 1,
  walletRsaPath: "",
  isShowSelectToken: false,
  isShowDisclaimer: false,
  investor: "",
  isGnosisSafe: false,

  walletLatency: 0,
  rpcStartTime: 0,
  ooBalance: 0,
  cartList: loadCartList(localStorage.getItem("chainKey") || "eth"),
  cartType: loadCartType(),
  nftPaymentToken: {},
  worldCupRound: 0,
  hideNew: false,
  showAd: true,
  orcaleAccountList: [],
  isSubscribeAccount: false,
  signedAccount: false,
  onlyDisplayStable: false,
});

export const getters = {
  default_account: () =>
    state.default_account || "0x0000000000000000000000000000000000000000",
  default_name: () => state.default_name,
  display_account: () => state.display_account,
  account: () => formatAccount(state.default_account || ""),
  chainKey: () => state.chainKey,
  chainId: () => state.chainId,
  walletPath: () => state.chainKey === "eth" ? "" : state.chainKey + "/",
  walletLogo: () =>
    baseURL +
    "/logos/" +
    (state.chainKey === "eth" ? "" : state.chainKey + "/"),
  walletIcon: () => state.walletIcon,
  walletName: () => state.walletName,
  layer2AccountId: () =>
    (state.layer2Account && state.layer2Account.accountId) || 0,
  layer2Markets: () => state.layer2Markets || [],
  strategyId: () => state.strategyId,
  priceScale: () => state.priceScale,
  volumeScale: () => state.volumeScale,
  stage: () => state.stage || 0,
  reloadReward: () => state.reloadReward || false,
  tokenMap: () => state.tokenMap || {},
  routePath: () => state.routePath || {},
  exchangeList: () => state.exchangeList || [],
  gasPrice: () => state.gasPrice || "",
  gasPriceFormat () {
    let gasPrice = "--";
    switch (state.chainKey) {
      case "eth":
        const gasLabel = window.$t(
          "gas_" +
          (window.localStorage.getItem("gasoption") || "").toLowerCase()
        );
        gasPrice =
          gasLabel +
          "(" +
          ((state.gasPrice &&
            BigNumber(state.gasPrice).div(BigNumber(10).pow(9)).toFixed(2)) ||
            "-") +
          " GWEI)";
        break;
      case "ont":
        gasPrice = "0.15 ONG";
        break;
      default:
        gasPrice =
          ((state.gasPrice &&
            BigNumber(state.gasPrice).div(BigNumber(10).pow(9)).toFixed(2)) ||
            "-") + " GWEI";
        break;
    }
    return gasPrice;
  },
  tolerance: () => state.tolerance || "",
  toleranceOptions: () => [
    {
      label: "0.5%",
      value: "0.5",
    },
    {
      label: "1%",
      value: "1",
    },
    {
      label: "3%",
      value: "3",
    },
  ],
  favoriteList: () => state.favoriteList || [],
  walletRsaPath: () => state.walletRsaPath || "",
  investor: () => state.investor,

  walletLatency: () => state.walletLatency,
  ooBalance: () => state.ooBalance || 0,
  nftPaymentToken: () => state.nftPaymentToken || {},
  hideNew: () => state.hideNew || false,
  showAd: () => state.showAd || true,
  orcaleAccountList: () => state.orcaleAccountList || [],
  isSubscribeAccount: () => state.isSubscribeAccount || false,
  signedAccount: () => state.signedAccount || false,
  onlyDisplayStable: () => state.onlyDisplayStable || false
};
export const removeCart = (chain, order) => {
  const list = state.cartList.filter(item => {
    return !(item.contract_address === order.contract_address && item.token_id === order.token_id);
  });
  state.cartList = list;
  window.localStorage.setItem(`nft_${chain}_cart`, JSON.stringify(list));
}

export const addCart = (chain, nft) => {
  const list = state.cartList.filter(item => {
    return item.contract_address === nft.contract_address && item.token_id === nft.token_id;
  });
  if (list && list.length > 0) return;
  if (nft && nft.order) {
    nft.order.amount = nft.order.amount || 1;
  }
  if (chain === "solana" || nft.type === 'sell' || nft.isFlagged) {
    nft.selected = "0";
  } else {
    nft.selected = "1";
  }
  nft.account = state.default_account || "";
  state.cartList = state.cartList.concat([nft]);
  window.localStorage.setItem(`nft_${chain}_cart`, JSON.stringify(state.cartList));
}

export const selectCart = (chain, order, clear) => {
  state.cartList.forEach(item => {
    if (item.contract_address === order.contract_address && item.token_id === order.token_id) {
      item.selected = order.selected === "1" ? "0" : "1";
    } else if (clear) {
      item.selected = "0";
    }
  });
  window.localStorage.setItem(`nft_${chain}_cart`, JSON.stringify(state.cartList));
}

export const unSelectCart = (chain, order) => {
  state.cartList.forEach(item => {
    if (item.contract_address === order.contract_address && item.token_id === order.token_id) {
      item.selected = "0";
    }
  });
  window.localStorage.setItem(`nft_${chain}_cart`, JSON.stringify(state.cartList));
}

export const clearCart = (chain) => {
  console.log('clearCart', chain);
  window.localStorage.removeItem(`nft_${chain}_cart`);
  state.cartList = [];
}

export const setCartType = (type) => {
  state.cartType = state.cartType === "buy" ? "sell" : "buy";
  window.localStorage.setItem(`nft_cart_mode`, state.cartType);
}

export const contract = state;
