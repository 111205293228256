/*
 * @Descripttion:
 * @version:
 * @Author: ZhaoWei
 * @QQ: 1512763623
 * @Date: 2020-11-12 15:26:11
 * @LastEditors: ABC
 * @LastEditTime: 2021-07-03 19:05:27
 */
const Decimal = require('decimal.js');
import { state } from "@/contract";
import { ooeSdk, connectWallet} from "@/connectWallet";
import { isApp } from "@/utils/helpers";
import { loadConfig } from "@/utils/localCache";

export const initPrototye = (Vue, router) => {
  Vue.prototype.$Decimal = Decimal

  Vue.prototype.$eventHub = Vue.prototype.$eventHub || new Vue()

  // 保留有效小数，位数不够加0，z为boolean时0去掉
  Vue.prototype.$toFixed = (n, k, z) => {
    if (isNaN(n)) {
      return 0
    }
    if (!k) k = 4
    let a = 10 ** k
    if (Number(n) < 0) {
      a = Math.ceil(Number(n) * a) / a
    } else {
      a = Math.floor(Number(n) * a) / a
    }
    if (z) {
      return a
    } else {
      return a.toFixed(k)
    }
  }

  Vue.prototype.$go = (path, params, op) => {
    if (path && path.indexOf('https://app.openocean.finance') != -1) {
      path = path.split('app.openocean.finance')[1]
    }
    if (path && path.indexOf('http') != -1) {
      window.open(path, '_blank')
      return
    }



    let currentRoute = router.currentRoute

    if (!params) params = {}
    if (currentRoute.params.chain && params && !params.chain) params.chain = currentRoute.params.chain
    if (["Swap", "LimitOrder","Dca"].indexOf(currentRoute.name) >= 0 && (!path || /^\/swap|^\/limitorder|^\/dca/gi.test(path))) {
      let name = 'Swap';
      if(params.tab === '02') name='LimitOrder'
      if(params.tab === '03') name='Dca'
      if (!path) {
        router.push({ name, params, query: currentRoute.query });
      } else {
        router.replace({ name, params, query: currentRoute.query });
      }
      return;
    }
    if (params && !path) {
      params = { ...currentRoute.params, ...params }
    }
    if (!path) {
      path = currentRoute.path
    }

    // if ((!params || !params.chain) && path) {
    //   let routes = router.options.routes
    //   let route = routes.find((item) => item.path.startsWith(path))
    //   if (route && route.path.indexOf('/:chain')) {
    //     path = `${path}/bsc`
    //   }
    // }

    let routes = router.options.routes
    let route = routes.find((item) => item.path.startsWith(path))

    if (params.chain && state.chainKey != params.chain && (route && route.path.indexOf('/:chain') == -1)) {

      state.account = null;
      state.default_account = null;
      state.default_name = null;
      state.display_account = null;

      state.chainKey = params.chain
      let chainObj = ooeSdk.config.chains.getChainByName(state.chainKey)
      if (isApp()) {
        connectWallet()
      } else {
        let isConnect = loadConfig('is_connect')
        if (isConnect) {
          let walletName = loadConfig(`${chainObj.compiler}_wallet`)
          connectWallet({
            walletKey:walletName
          })
        }
      }
    }

    try {
      if (path == currentRoute.path) {
        router.replace({ path: path, query: params });
      } else {
        router.push({ path: path, query: params });
      }
    } catch (error) {

    }
    // history.replaceState({path: path}, null, path);
  }
}
