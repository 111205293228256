import Vue from "vue";
import { OpenoceanSdk } from "@openocean.finance/openocean-sdk";
import { getSidName } from "@openocean.finance/wallet";
import { state } from "@/contract";
import connectWalletModal from "@/components/ConnectWalletModal";
import showToast from "@/components/toast";
import { sleep } from "@/utils";
import { getCache, setCache } from "@/utils/cache";
import { isApp, isMobile, isGnosisSafe } from "@/utils/helpers";
import Web3 from "web3";
import { loadConfig, saveConfig, removeConfig } from "@/utils/localCache";
import SafeAppsSDK from "@gnosis.pm/safe-apps-sdk";
import getChains from "@/utils/getChains";
import { solanaRPC } from "@/utils/config";
import BigNumber from "bignumber.js";

let onDisconnect = null;
let onAccountsChanged = null;
let onChainChanged = null;
let currentProvider = null;

export let myWallet = {};
export const ooeSdk = new OpenoceanSdk();
export const { swapSdk, api, web3, config } = ooeSdk;

export async function connectWallet (p) {
  const params = await getParams(p);
  const { compiler, chainKey, walletKey } = params || {};
  let ooeSwapSdk = null;
  if (walletKey) {
    let t = new Date().getTime();
    ooeSwapSdk = await tryConnect(chainKey, walletKey);
    console.log("-----tryConnect time :", new Date().getTime() - t);
  } else {
    ooeSwapSdk = await connectWalletModal(params);
  }
  if (!ooeSwapSdk) return;
  if (!ooeSwapSdk.wallet || !ooeSwapSdk.wallet.address) {
    await sleep(1000);
  }
  // if (!ooeSwapSdk.wallet || !ooeSwapSdk.wallet.address) {
  //   let modal = ooeSwapSdk.wallet.modal
  //   const provider = modal.getWalletProvider()
  //   const isConnected = modal.getIsConnectedState()
  //   disConnect()
  //   return
  // }
  const { sdk, key, icon } = ooeSwapSdk.wallet;
  state.chainKey = chainKey;
  state.walletKey = key;
  state.walletIcon = icon;
  myWallet = sdk;
  currentProvider = sdk.currentProvider;
  saveConfig(`${compiler}_wallet`, `${state.walletKey}`);
  saveConfig(`${state.chainKey}_wallet`, `${state.walletKey}`);
  saveConfig(`is_connect`, chainKey);
  changeAccount(ooeSwapSdk);
  addWalletEvent(ooeSwapSdk);
  return ooeSwapSdk;
}

let tryKey = 0;
export async function tryConnect (chainName, walletName, option) {
  if (tryKey) {
    return;
  }
  tryKey = 1;
  setTimeout(() => {
    tryKey = 0;
  }, 1000);

  const params = {
    chainName: chainName,
    walletName: walletName,
  };
  let rpc = getCurrentRPC();
  if (chainName == "solana") {
    rpc = solanaRPC || "https://cold-hanni-fast-mainnet.helius-rpc.com";
  }
  if (rpc) {
    params["localRpcUrl"] = rpc;
  }
  if (option && option.provider) {
    params["provider"] = option.provider;
  }
  await ooeSdk.swapSdk.connectWallet(params);
  tryKey = 0;
  if (
    !isGnosisSafe() &&
    ooeSdk.swapSdk &&
    ooeSdk.swapSdk.wallet &&
    ooeSdk.swapSdk.wallet.sdk &&
    ooeSdk.swapSdk.wallet.sdk.eth
  ) {
    try {
      ooeSdk.swapSdk.wallet.sdk.eth.getGasPrice().then((_gasPrice) => {
        if (
          _gasPrice &&
          state.gasPrice &&
          BigNumber(_gasPrice).comparedTo(state.gasPrice) < 0
        ) {
          state.gasPrice = _gasPrice;
        }
      });
    } catch (e) {
      // console.log('this.wallet.sdk.eth.getGasPrice', e);
    }
  }
  return ooeSdk.swapSdk;
}

export async function addWalletEvent (ooeSwapSdk) {
  const { sdk, name, modal } = ooeSwapSdk.wallet;
  if (currentProvider && currentProvider.removeListener) {
    try {
      const event = {
        disconnect: onDisconnect,
        accountsChanged: onAccountsChanged,
        chainChanged: onChainChanged,
      };
      for (let key in event) {
        if (typeof event[key] === "function") {
          currentProvider.removeListener(key, event[key]);
        }
      }

      await sleep(1000);

    } catch (error) {
      console.log("error---", error);
    }
  }
  if (currentProvider && currentProvider.on) {
    onDisconnect = currentProvider.on("disconnect", (args, payload) => {
      debugger
      disConnect(true);
    });
    onAccountsChanged = currentProvider.on("accountsChanged", async (args) => {
      const { currentProvider } = sdk;
      if (currentProvider && currentProvider._metamask) {
        const isUnLock = await currentProvider._metamask.isUnlocked();
        if (!isUnLock) {
          showToast(
            "There is no connected accounts. Please, connect at least 1 account in your wallet."
          );
          disConnect(true);
          return;
        }
      }
      let account =
        currentProvider.accounts && currentProvider.accounts[0]
          ? currentProvider.accounts[0]
          : args[0];
      console.log("accountsChanged___" + account);
      if (ooeSwapSdk.wallet.address != account) {
        showToast(window.$t("wallet_message_50002", { wallet: name }));
        ooeSwapSdk.wallet.address = account;
        changeAccount(ooeSwapSdk);
      }
    });
    let chainTime;
    onChainChanged = currentProvider.on("chainChanged", async (args) => {

      let currentChainId = parseInt(args, 16);
      console.log("chainChanged___" + currentChainId);
      if (chainTime) clearTimeout(chainTime);
      chainTime = setTimeout(async () => {
        const _currrentChainId =
          ooeSdk.config.chains.chainObj[state.chainKey].chainId;
        if (currentChainId === _currrentChainId) return;
        const chain = ooeSdk.config.chains.chainList.find((item) => {
          return item.chainId === currentChainId && item.disabled !== true;
        });
        if (chain) {
          changeChain(chain.key);
        } else {
          if (name !== 'WalletConnect') {
            disConnect();
          }
        }
      }, 400);
    });


    let accountTime
    let chainTime1
    if (modal && name == 'WalletConnect') {
      modal.subscribeAccount((data) => {
        let address = data.address
        if (accountTime) clearTimeout(accountTime);
        accountTime = setTimeout(async () => {
          if (ooeSwapSdk.wallet.address != address) {
            showToast(window.$t("wallet_message_50002", { wallet: name }));
            ooeSwapSdk.wallet.address = address;
            changeAccount(ooeSwapSdk);
          }
        }, 400);
      })
      modal.subscribeNetwork((data) => {
        let chainId = data.chainId

        if (chainTime1) clearTimeout(chainTime1);
        chainTime1 = setTimeout(async () => {
          const chain = ooeSdk.config.chains.chainList.find((item) => {
            return item.chainId == chainId && item.disabled !== true;
          });
          if (chain) {
            changeChain(chain.key);
          }
        }, 400);
      })
    }
  }
}

export async function getParams (p) {
  let walletKey = p && p.walletKey;
  let chainKey = (p && p.chainKey) || state.chainKey;
  if (isApp()) {
    let appObj = await getAppConnect();
    walletKey = appObj.walletKey;
  } else if (isMobile()) {
    walletKey = "WalletConnect";
  } else if (isGnosisSafe()) {
    state.isGnosisSafe = true;
    let appObj = await getGnosisSafeConnect();
    chainKey = appObj.chainKey;
    walletKey = appObj.walletKey;
  }
  const chainObj = ooeSdk.config.chains.getChainByName(chainKey);
  const { compiler } = chainObj || {};
  if (walletKey) {
    let wallet = ooeSdk.config.wallets.walletObj[walletKey];
    if (!wallet || wallet.supportChains.indexOf(chainKey) == -1) {
      let compilerWalletName = loadConfig(`${compiler}_wallet`);
      let compilerWallet = ooeSdk.config.wallets.walletObj[compilerWalletName];
      if (
        compilerWallet &&
        compilerWallet.supportChains.indexOf(chainKey) !== -1
      ) {
        walletKey = compilerWalletName;
      } else {
        let walletName = loadConfig(`${chainKey}_wallet`);
        if (walletName) {
          walletKey = walletName;
        } else {
          return;
        }
      }
    }
  }

  return {
    compiler,
    chainKey,
    walletKey,
  };
}

export async function getAppConnect () {
  let chainKey = "eth";
  let walletKey = "MetaMask";
  if (
    window.coin98 &&
    window.coin98.sol &&
    window.coin98.sol.chainId === "undefined"
  ) {
    console.log("window.coin98");
    chainKey = "solana";
    walletKey = "Coin98Wallet";
  } else if (
    window.coin98 &&
    window.coin98.sol &&
    window.coin98.sol.chainId !== "undefined"
  ) {
    let chainId = await new Web3(Web3.givenProvider).eth.getChainId();
    let chain = config.chains.getChainById(chainId);
    if (chain) chainKey = chain.key;
    walletKey = "Coin98Wallet";
  } else if (window.solana && window.solana.isSafePalWallet) {
    chainKey = "solana";
    walletKey = "SlopeWallet";
    console.log(" safepal solana");
    // showToast(
    //   "Sorry we currently haven't enable solane swap on safepal wallet."
    // );
    return;
  } else {
    let chainId = await new Web3(
      Web3.givenProvider || window.ethereum || window.safepal_wallet_app
    ).eth.getChainId();

    let chain = config.chains.getChainById(chainId);
    if (chain) chainKey = chain.key;
  }
  return {
    chainKey,
    walletKey,
  };
}

export async function getGnosisSafeConnect () {
  try {
    const opts = {
      // allowedDomains: [/gnosis-safe.io/],
      allowedDomains: [
        /gnosis-safe.io$/,
        /app.safe.global$/,
        /wallet-test.secux.io$/,
        /wallet.secuxtech.com$/,
        /wallet.ambire.com$/,
        /bnb.zebec.io$/,
      ],
      debug: false,
    };
    const safeAppsSDK = new SafeAppsSDK(opts);
    const res = await safeAppsSDK.safe.getChainInfo();
    console.log("safeAppsSDK getChainInfo", res);
    const { chainId } = await safeAppsSDK.safe.getInfo();
    console.log("safeAppsSDK", chainId);
    const chains = getChains();
    const chainKey = chains.find((c) => c.chainId === chainId).walletValue;
    return {
      chainKey,
      walletKey: "GnosisSafeWallet",
    };
  } catch (e) {
    console.log("initGnosisSafe error", e);
    return {};
  }
}

export async function changeAccount (connectWalletObj) {
  const { account, address } = connectWalletObj.wallet;
  state.account = address;
  state.default_account = address;
  state.display_account = account;
  state.default_name = "";
  getSidName(connectWalletObj.wallet).then(() => {
    const { sidName } = connectWalletObj.wallet;
    console.log('getSidName', sidName);
    state.default_name = sidName;
  })
}

export async function disConnect (isRealDis) {
  state.account = null;
  state.default_account = null;
  state.default_name = null;
  if (swapSdk.wallet) swapSdk.wallet.sidName = null;
  state.display_account = null;
  if (isRealDis) {
    // let chainObj = ooeSdk.config.chains.getChainByName(state.chainKey)
    for (let i = 0; i < ooeSdk.config.chains.chainList.length; i++) {
      let chain = ooeSdk.config.chains.chainList[i];
      removeConfig(`${chain.compiler || chain.key}_wallet`);
    }
    removeConfig(`is_connect`);

    const { sdk, name, key, modal } = swapSdk.wallet;

    if (modal && name == 'WalletConnect') {
      try {
        swapSdk.wallet.disconnect()
      } catch (error) {
        console.log('disconnect-----error')
        console.log(error)
      }
    } else if (sdk.currentProvider && sdk.currentProvider.disconnect) {
      await sdk.currentProvider.disconnect();
    }
  }
}

export async function changeChain (chainKey) {
  Vue.prototype.$go("", {
    chain: chainKey,
  });
}

export function getCurrentRPC () {
  const rpcNode = getCache("rpc-setting") || "{}";
  const { url, expiration } = JSON.parse(rpcNode)[state.chainKey] || {};
  if (url && expiration && new Date().getTime() < expiration) {
    return url;
  } else {
    return "";
  }
}
