<template>
  <transition name="fadeBack" @after-leave="handleLeave">
    <div v-show="isShow" ref="scrollDom" class="nou-dialog nou-g-box" @click="handleClickModal">
      <div class="nou-dialog__container__wrapper">
        <transition name="fade">
          <div
              v-show="isShow"
              class="nou-dialog__container"
              :class="{...dialogClass, 'fixed-mobile':fixedMobile}"
              :style="{ width: fitContent? 'fit-content' : ''}"
              @click.stop
          >
            <div class="g-dialog-title" v-if="title.length > 0">
              <h2 class="nou-dialog__title g-font-bold">{{ title }}</h2>
              <slot name="close">
                <div class="g-close-button" @click="close" v-if="showClose">
                  <i
                    class="iconfont iconClose nou-dialog__close nou-g-btn--hover"
                  />
                </div>
              </slot>
            </div>
            <slot v-if="contentActive"/>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>
<script>
import {lock, unlock} from 'tua-body-scroll-lock'
import {forceUnlock} from '@/utils/helpers.js'

/**
 * dialog
 * @author yuanzeyu
 * @date 2019-12-23
 * @desc
 */
export default {
  name: 'NouDialog',
  props: {
    width: {
      type: [String, Number],
      default: 400
    },
    visible: {
      type: Boolean,
      require: false,
      default: true
    },
    keepSlot: { // keep slot instead of recreated
      type: Boolean,
      require: false,
      default: false
    },
    title: {
      type: String,
      require: true,
      default: ''
    },
    closeOnClickModal: {
      type: Boolean,
      default: false
    },
    dialogClass: {
      type: Object,
      require: false,
      default: () => {
      }
    },
    showClose: {
      type: Boolean,
      require: false,
      default: false
    },
    fixedMobile: { // fixed bottom when mobile
      type: Boolean,
      require: false,
      default: false
    },
    rightIcon: {
      type: Boolean,
      require: false,
      default: false
    },
    checkDom: {
      type: String,
      require: false,
      default: 'js-dialog-scroll-dom',
    },
    fitContent:{
      type:Boolean,
      require:false,
      default:false
    },
    noLock: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isShow: false,
      contentActive: false,
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          this.show()
        } else {
          this.close()
        }
      },
      immediate: true
    },
    checkDom(val){
      if(val && val === this.scrollDomID ) {
        const dom = document.getElementById(this.scrollDomID);
        lock(dom)
        unlock(dom)
      }
    }
  },
  beforeDestroy () {
    unlock(this.$refs.scrollDom) // unlock when destroy custom
  },
  created () {
    if (this.keepSlot) {
      this.contentActive = true
    }
  },
  methods: {
    unlockScroll() {
      if(this.noLock){
        return
      }
      const dom = document.getElementById(this.scrollDomID);
      if (dom) {
        console.log('weunlock', dom)
        unlock(dom); // unlock when destroy custom
        document.body.style.overflow="auto"
      } else {
        unlock();
        forceUnlock();
        document.body.style.overflow="auto"
      }
    },
    lockScroll() {
      if(this.noLock){
        return
      }
      const dom = document.getElementById(this.scrollDomID);
      if (dom) {
        lock(dom); // unlock when destroy custom
      } else {
        lock();
      }
    },
    close() { // public
      this.unlockScroll();
      document.body.style.overflow = 'auto';
      this.isShow = false;
      this.$emit('update:visible', false);
    },
    handleClickModal () {
      if (this.closeOnClickModal) {
        this.close()
      }
    },
    show () {
      this.isShow = true
      this.contentActive = true
      if (!this.noLock) {
        this.$nextTick(() => {
          lock(this.$refs.scrollDom)
        })
      }
    },
    handleLeave () {
      if (!this.keepSlot) { // destroy slot after leave
        this.contentActive = false // avoid view change between transition
      }
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
$g-screen-flag: 750px;
.nou-dialog {
  // todo remove pc
  z-index: 9998;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--dialog-mask);
  cursor: initial;
  overflow: auto;
}

.nou-dialog__container__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}

.nou-dialog__container {
  position: relative;
  margin: 100px 0;
  max-width: 100%;
  min-width: 330px;
  padding: 96px 24px 24px;
  border-radius: 24px;
  background: var(--dialog-content);
  text-align: left;
  box-sizing: border-box;

  @media (max-width: $g-screen-flag) {
    margin: 1rem 0.4rem;
    // width: 5.9rem;
    // padding: 0.4rem;

    &.fixed-mobile {
      position: fixed;
      top: initial;
      bottom: 0;
      left: 50%;
      margin: 0;
      transform: translate3d(-50%, 0, 0);
    }
  }

  @media (max-width: 440px){
    // width: calc(100% - .6rem);
    width:100%;
  }
}

.nou-dialog__title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.g-close-button {
  top: 24px;
}

.nou-dialog__closeBox {
  position: absolute;
  right: 20px;
  top: 22px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: var(--classic-back-bg);
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  .nou-dialog__close {
    font-size: 10px;
    color: var(--color-content);
    display: block;
    margin: 0;
    @media (max-width: $g-screen-flag) {
      width: 0.28rem;
      height: 0.28rem;
      font-size: 0.28rem;
      margin-top: 0.07rem;
    }
  }
  &:hover{opacity: 0.8;}
}
.nou-dialog__title {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 21px;

  @media (max-width: $g-screen-flag) {
    font-size: 0.36rem;
    line-height: 0.42rem;
    // padding-bottom: 0.4rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  //transition: all .5s;
  //transform: translateZ(0);
}

.fade-enter,
.fade-leave-active {
  //opacity: 0;
  //transform: translate3d(0, 100%, 0);
}

.fadeBack-enter-active,
.fadeBack-leave-active {
  transition: all 0.5s;
  opacity: 1;
}

.fadeBack-enter,
.fadeBack-leave-active {
  opacity: 0;
}

.rightIcon {
  padding-right: 20px;
  h2 {
    font-size: 15px !important;
  }
}
</style>

