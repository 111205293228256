import axios from 'axios'
import { baseURL, baseProURL, marketApiURL, baseSaasURL, baseSpotURL } from "./config";
import Vue from 'vue'
import { isCustomGas, getChainId } from "@/utils/getChains";
import { getCache, setCache, REQUEST_CACHE_KEY } from './localCache';
import { contract as state } from "@/contract";

// axios 配置
axios.defaults.timeout = 30000
axios.defaults.baseURL = baseURL + '/v1/'
console.log('baseURL', baseURL)

const EXPIRE_TIME = 60 * 1000
const CancelToken = axios.CancelToken

function setLocalCache (config) {
    if (!config || !config.cache) return;
    // 如果需要缓存--考虑到并不是所有接口都需要缓存的情况
    const cache = getCache(REQUEST_CACHE_KEY);
    let source = CancelToken.source()
    config.cancelToken = source.token
    // 去缓存池获取缓存数据
    let data = cache[config.url]
    // 获取当前时间戳
    let now = new Date().getTime()
    // 判断缓存池中是否存在已有数据 存在的话 再判断是否过期
    // 未过期 source.cancel会取消当前的请求 并将内容返回到拦截器的err中
    const expire_time = config.expire_time || EXPIRE_TIME;
    if (data && now - data.expire < expire_time) {
        source.cancel(data)
    } else {
        delete cache[config.url];
        setCache(REQUEST_CACHE_KEY, cache);
    }
}
//请求拦截器
axios.interceptors.request.use(
    function (config) {
        if (/designated_tokenList/.test(config.url)) {
            config.cache = true;
            config.expire_time = 1000 * 30;
            setLocalCache(config);
            return config;
        }
        if (/\/nft\//.test(config.url)) {
            setLocalCache(config);
            return config;
        }
        if (/\/tokenbasics|\/getfee/.test(config.url)) {
            // config.baseURL = "https://bridge.poly.network/testnet/v1";
            config.baseURL = "https://bridge.poly.network/v1";
            return config;
        }
        if (/\/v2\/tokens|\/v2\/swaps/.test(config.url)) {
            // config.baseURL = "https://bridge.poly.network/testnet/v1";
            config.baseURL = "https://api.binance.org/bridge/api";
            return config;
        }
        if (/\/mapping/.test(config.url)) {
            // config.baseURL = "https://bridge.poly.network/testnet/v1";
            config.baseURL = "https://tokenmapper.api.matic.today/api/v1";
            return config;
        }
        if (/\/v1\/getcrosstx/gi.test(config.url)) {
            config.baseURL = "https://explorer.poly.network/api";
            return config;
        }
        if (/\/v1\/deposit|\/v2\/withdraw/gi.test(config.url)) {
            config.baseURL = "https://bridge-api.matic.network";
            return config;
        }
        if (/\/calcOutGivenIn/gi.test(config.url)) {
            // config.baseURL = "https://bridge.poly.network/testnet/v1";
            config.baseURL = "https://swap.poly.network";
            return config;
        }
        if (/\/saas/gi.test(config.url)) {
            config.baseURL = baseSaasURL;
        }
        // if(/avax\//gi.test(config.url) && /\/exchange|\/dex|\/quote|\/swap/gi.test(config.url) && !/\/swap-tx/gi.test(config.url)) {
        //     config.baseURL = baseURL + '/v2/';
        //     config.url = config.url.replace('avax/', '43114/').replace('swap-quote', 'swap');
        // }
        changeEthereumUrl(config, '1')
        changeUrl(config, 'ropsten', '3')
        changeUrl(config, 'optimism', '10')
        changeUrl(config, 'boba', '288')
        changeUrl(config, 'xdai', '100')
        changeUrl(config, 'avax', '43114')
        changeUrl(config, 'bsc', '56')
        changeUrl(config, 'bsctest', '97')
        changeUrl(config, 'fantom', '250')
        changeUrl(config, 'polygon', '137')
        changeUrl(config, 'moonriver', '1285')
        changeUrl(config, 'arbitrum', '42161')
        changeUrl(config, 'heco', '128')
        changeUrl(config, 'okex', '66')
        changeUrl(config, 'aurora', '1313161554')
        changeUrl(config, 'cronos', '25')
        changeUrl(config, 'harmony', '1666600000')
        changeUrl(config, 'rinkeby', '4')
        changeUrl(config, 'ontevm', '58')
        changeUrl(config, 'metis', '1088')
        changeUrl(config, 'kava', '2222')
        changeUrl(config, 'celo', '42220')
        changeUrl(config, 'klaytn', '8217')
        changeUrl(config, 'zksync', '324')
        changeUrl(config, 'linea', '59144')
        changeUrl(config, 'polygon_zkevm', '1101')
        changeUrl(config, 'telos', '40')
        changeUrl(config, 'scroll', '534352')
        changeUrl(config, 'base', '8453')
        changeUrl(config, 'opbnb', '204')
        changeUrl(config, 'mantle', '5000')
        changeUrl(config, 'manta', '169')
        changeUrl(config, 'x1', '195')
        changeUrl(config, 'blast', '81457')
        changeUrl(config, 'mode', '34443')
        changeUrl(config, 'pulse', '369')
        changeUrl(config, 'merlin', '4200')
        changeUrl(config, 'rootstock', '30')
        changeUrl(config, 'sei', '1329')
        changeUrl(config, 'arbitrum_sepolia', '421614')
        changeUrl(config, 'gravity', '1625')
        changeUrl(config, 'ape', '33139')

        // if (/swap-tx/gi.test(config.url)) {
        //     config.url = /^\/swap-tx/gi.test(config.url) ? '/1' + config.url : config.url;
        //     config.baseURL = 'https://open-api.openocean.finance/v3/';
        // }
        if (/terra\/gas-price/gi.test(config.url)) {
            config.baseURL = baseURL + '/v1';
        }
        // 如果需要缓存--考虑到并不是所有接口都需要缓存的情况
        setLocalCache(config);

        if (!/api\.binance\.com|apiasia\.tronscan\.io|kline\.openocean\.finance|api\.squidrouter\.com|api\.0xsquid\.com/gi.test(config.url) && config.headers && config.headers.common) {
            config.headers.common["token"] = Vue.$cookies.get('ccat') || '';
        }

        if (/\/market/.test(config.url)) {
            // TODO 将/market开头的地址临时切换到生产域名（发布正式需删除）
            config.baseURL = 'https://proapi.openocean.finance'
        }
        // \market|
        if (/\/exchange\/exchange\/perpetual|\/exchange\/account/gi.test(config.url)) {
            if (!/markets/gi.test(config.url)) {
                config.baseURL = baseProURL;
            }
        }
        if (/\/spot\//gi.test(config.url) && !/\/exchange\//gi.test(config.url)) {
            config.baseURL = baseSpotURL
        }
        if (/\/api\/v3/gi.test(config.url)) {
            config.baseURL = '';
        }
        if(/token$|\/v2\/getApr|\/v2\/getNewApr|\/v2\/chart|\/v1\/cms\/|\/v1\/cross\/|\/v1\/data\/|\/v1\/ubt\/submit|\/v1\/graphql|\/v1\/llama|\/v3\/notice|\/v3\/detail|\/v3\/activity|\/v3\/airdrop|\/v3\/referral/gi.test(config.url)) {
            // config.baseURL = 'https://market-api.openocean.finance';
            // config.baseURL = 'https://pre-market-api.openocean.finance';
            config.baseURL = marketApiURL
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

//响应拦截器
axios.interceptors.response.use(
    function (response) {
        // 只缓存get请求
        if (response.config.method === 'get' && response.config.cache) {
            // 缓存数据 并将当前时间存入 方便之后判断是否过期
            let data = {
                expire: new Date().getTime(),
                data: response.data
            }
            const cache = getCache(REQUEST_CACHE_KEY)
            if (!response.config.check || response.config.check(data)) {
                cache[`${response.config.url}`] = data;
            } else {
                delete cache[response.config.url];
            }
            setCache(REQUEST_CACHE_KEY, cache);
        }
        if (response.config.swapChainId && !response.data.chainId) {
            response.data.chainId = response.config.swapChainId;
        }
        return response.data;
    },
    function (error) {
        // 请求拦截器中的source.cancel会将内容发送到error中
        // 通过axios.isCancel(error)来判断是否返回有数据 有的话直接返回给用户
        if (axios.isCancel(error)) return Promise.resolve(error.message.data)
        return Promise.reject(error);
    }
);

function changeUrl (config, name, chainId) {
    if ((new RegExp(`${name}\/`, 'gi').test(config.url)) && /\/exchange|\/dex|\/quote|\/swap-quote|\/meta-swap/gi.test(config.url)) {
        if (/\/swap-quote/gi.test(config.url)) {
            config.swapChainId = chainId;
        }
        config.baseURL = baseURL + '/v2/'
        config.url = config.url.replace(`${name}/`, `${chainId}/`).replace('swap-quote', 'swap')
    } else if (isCustomGas(name) && /\/gas-price/gi.test(config.url)) {
        config.baseURL = baseURL + '/v2/'
        config.url = config.url.replace(`${name}/`, `${chainId}/`)
    }
}

function changeEthereumUrl (config) {
    if (/^gas-price$|^exchange$|^dex$|^quote|^swap-quote/gi.test(config.url)) {
        if (/^swap-quote/gi.test(config.url)) {
            config.swapChainId = 1;
        }
        config.baseURL = baseURL + '/v2/1/'
        config.url = config.url.replace('swap-quote', 'swap')
    }
}

export default axios;