import { getDefaultToken } from "@/utils/getChains";

export const TOKEN_CACHE_KEY = "openocean_token";
export const CONFIG_CACHE_KEY = "openocean_config";
export const REQUEST_CACHE_KEY = "openocean_request";

export const getCache = (key) => {
  const cache = window.localStorage.getItem(key);
  return cache ? JSON.parse(cache) : {};
}

export const setCache = (key, cache) => {
  window.localStorage.setItem(key, JSON.stringify(cache));
}

export const loadToken = (code, defaultToken) => {
  return;
  const cache = getCache(TOKEN_CACHE_KEY);
  const chainKey = localStorage.getItem("chainKey") || "eth";
  const key = `${chainKey}_${code}`;
  const token = cache[key];
  if (!defaultToken) {
    const tokenList = getDefaultToken(chainKey);
    defaultToken = tokenList[code === "tokenSwapPrev" ? 0 : 1];
  }
  window.localStorage.removeItem(key);
  return Object.assign(
    {
      price: 0,
      usd: 0,
      balance: 0,
      approve: 0,
      usdtValue: 0,
      value: 1,
      balanceDecimals: 0,
    },
    token ? token : defaultToken
  );
};

export const saveToken = (key, token) => {
  window.localStorage.removeItem(TOKEN_CACHE_KEY);
  // const cache = getCache(TOKEN_CACHE_KEY);
  // cache[key] = token;
  // setCache(TOKEN_CACHE_KEY, cache);
};

export const loadConfig = (key, def) => {
  const cache = getCache(CONFIG_CACHE_KEY);
  return cache[key] || def;
};

export const saveConfig = (key, config) => {
  const cache = getCache(CONFIG_CACHE_KEY);
  cache[key] = config;
  setCache(CONFIG_CACHE_KEY, cache);
};

export const removeConfig = (key) => {
  const cache = getCache(CONFIG_CACHE_KEY);
  delete cache[key];
  setCache(CONFIG_CACHE_KEY, cache);
};
