import axios from "axios";
import { formateTime, dateFormat } from "@/pages/ClassicV3/components/chart/common";
import BigNumber from "bignumber.js";
import { openApiURL, marketApiURL, coingeckoURL } from "@/utils/config";
import { isV2 } from "@/utils/getChains";

const EXPIRE_TIME = 1000 * 10;

const NetworkMap = {
  eth: "ethereum",
  avax: "avalanche",
  bsc: "binance-smart-chain",
  okex: "okex-chain",
  polygon: "polygon-pos",
  heco: "huobi-token",
  ont: "ontology",
  harmony: "harmony",
  dot: "polkadot",
};

export const getTokenPriceByIds = async (token, days, path) => {
  const url = `${coingeckoURL}/${token}/${path || "market_chart"
    }?vs_currency=usd&days=${days || 1}`;
  return await axios.get(url, { cache: true, expire_time: EXPIRE_TIME * 6 });
};

export const getTokenPriceByAddress = async (tokenAddress, days, network) => {
  const _network = NetworkMap[network] || network;
  const url = `${coingeckoURL}/${_network}/contract/${tokenAddress.toLowerCase()}/market_chart/?vs_currency=usd&days=${days || 1
    }`;
  return await axios.get(url, { cache: true, expire_time: EXPIRE_TIME * 6 });
};

export const getTokenPriceBySymbol = async (chain, tokens) => {
  const url = `${openApiURL}/v3/${chain}/designated_tokenList?tokens=${tokens.join(',')}`;
  const { data = [] } = await axios.get(url, {
    cache: true,
    expire_time: EXPIRE_TIME
  });
  const res = {};
  data.forEach((item) => {
    const { symbol } = item;
    res[symbol] = item;
  });
  return res;
};

export const getWhiteToken = async (chain) => {
  try {
    if (!isV2(chain)) return [];

    const url = `${openApiURL}/v3/${chain}/white_token`;
    const { data = [] } = await axios.get(url, {
      cache: true,
      expire_time: EXPIRE_TIME * 6
    });
    return (
      data &&
      data.map((item) => {
        return item && item.toLowerCase();
      })
    );
  } catch (e) {
    return [];
  }
};

export const scanChain = async (chain, hash) => {
  try {
    await axios.post(`${marketApiURL}/v1/${chain}/scan_chain`, {
      hash,
    });
  } catch (e) {
    console.log("", e);
  }
};

export const getDecimalPlaces = (item) => {
  if (!item) return 4;
  const temp = new BigNumber(item).toFixed(20);
  const zero = temp.replace(/^0\.0+/, "");
  const decimalPlaces = temp.length - zero.length + 4;
  return decimalPlaces;
};

export const getChartsV2 = async (
  token1,
  token2,
  token1Address,
  token2Address,
  timeLimit,
  network
) => {
  try {
    const days = {
      "1d": "1",
      "1w": "7",
      "1m": "30",
      "1y": "365",
      all: "max",
    }[timeLimit];
    // if (navigator.language === 'zh-CN') {
    //   const _result = await axios.post(`/v2/chart`, {
    //     token1,
    //     token2,
    //     token1Address,
    //     token2Address,
    //     timeLimit,
    //     network
    //   });
    //   return _result;
    // }
    let res1, res2;
    if (token1 && token2) {
      res1 = await getTokenPriceByIds(token1, days);

      if (["binance-usd", "usd-coin", "tether", "dai"].indexOf(token2) >= 0) {
        res2 = {
          prices: res1.prices.map((item) => {
            return [item[0], 1];
          }),
        };
      } else {
        res2 = await getTokenPriceByIds(token2, days);
      }
    } else {
      res1 = await getTokenPriceByAddress(token1Address, days, network);
      res2 = await getTokenPriceByAddress(token2Address, days, network);
    }

    if (!res1 || !res1.prices || !res1 || !res2.prices) return [];

    const prices1 = res1.prices || [];
    const prices2 = res2.prices || [];

    const listMap = {};
    const step =
      {
        "1d": 60,
        "1w": 60 * 2,
        "1m": 60 * 24,
        "1y": 60 * 24 * 12,
        all: 60 * 24 * 12,

        // '5m': 5,
        // '15m': 15,
        // '30m': 30,
        // '1h': 60,
        // '1d': 24 * 10,
      }[timeLimit] *
      60 *
      1000;
    const decimalPlaces1 = getDecimalPlaces(prices1[0][1]);
    const decimalPlaces2 = getDecimalPlaces(prices2[0][1]);
    const decimalPlaces3 = getDecimalPlaces(
      prices1[0][1] > prices2[0][1]
        ? prices2[0][1] / prices1[0][1]
        : prices1[0][1] / prices2[0][1]
    );
    const decimalPlaces4 =
      decimalPlaces1 > decimalPlaces2 ? decimalPlaces1 : decimalPlaces2;
    const decimalPlaces =
      decimalPlaces3 > decimalPlaces4 ? decimalPlaces3 : decimalPlaces4;
    prices1.map((item) => {
      item[1] = new BigNumber(item[1]).toFixed(decimalPlaces);
      let dt = new Date(Math.ceil(item[0] / step) * step);
      let time = dateFormat(dt, "yyyy-MM-dd-hh-mm");
      if (listMap[time]) {
        dt = new Date(Math.floor(item[0] / step) * step);
        time = dateFormat(dt, "yyyy-MM-dd-hh-mm");
        listMap[time] = {
          time: dt.getTime(),
          value1: item[1],
        };
      } else {
        listMap[time] = {
          time: dt.getTime(),
          value1: item[1],
        };
      }
    });
    prices2.map((item) => {
      item[1] = new BigNumber(item[1]).toFixed(decimalPlaces);
      let dt = new Date(Math.ceil(item[0] / step) * step);
      let time = dateFormat(dt, "yyyy-MM-dd-hh-mm");
      if (listMap[time]) {
        if (listMap[time].value2) {
          dt = new Date(Math.floor(item[0] / step) * step);
          time = dateFormat(dt, "yyyy-MM-dd-hh-mm");
          if (listMap[time]) {
            listMap[time].value2 = item[1];
          } else {
            console.log("listMap[time]", listMap[time]);
          }
        } else {
          listMap[time].value2 = item[1];
        }
      } else {
        dt = new Date(Math.floor(item[0] / step) * step);
        time = dateFormat(dt, "yyyy-MM-dd-hh-mm");
        if (listMap[time]) {
          listMap[time].value2 = item[1];
        } else {
          console.log("listMap[time]", listMap[time]);
        }
      }
    });
    const list = {};
    let start = null;
    let end = null;
    for (var key in listMap) {
      const item = listMap[key];
      if (item.value2 > 0 && item.value1 > 0) {
        const value = item.value1 / item.value2;
        const _time = formateTime(item.time);
        list[_time] = value.toFixed(decimalPlaces);
        if (!start) {
          start = _time;
        }
        end = _time;
      }
    }
    const result = [];
    const _step = step / 1000;
    for (let i = start; i < end; i += _step) {
      if (list[i]) {
        result.push({
          time: i,
          value: +list[i],
        });
      } else {
        let seq = 1;
        let _item = list[i - _step * seq];
        while (!_item) {
          seq++;
          _item = list[i - _step * seq];
        }
        result.push({
          time: i,
          value: +_item,
        });
      }
    }
    return result;
  } catch (e) {
    return [];
  }
};

function setKeyData (list, oneKey) {
  let newList = [];
  let oneItem = null;
  list.map((item, i) => {
    let hour = new Date(item[0]).getHours();
    if (!oneItem) {
      oneItem = item;
    } else {
      if (oneItem[1] < item[1]) oneItem[1] = item[1];
      if (oneItem[2] > item[2]) oneItem[2] = item[2];
    }
    if (
      (oneKey == 0 && hour == 0) ||
      hour % oneKey == 0 ||
      (i == list.length - 1 && oneItem)
    ) {
      oneItem[3] = item[3];
      newList.push(oneItem);
      oneItem = null;
    }

    // console.log('hour:' + hour)
  });

  // console.log(list.length)
  // console.log(newList.length)
  return newList;
}
export const getChartsV3 = async (token1, token2, timeLimit, network) => {
  if (!token1 || !token2) return [];
  try {
    const days = {
      "30m": "1",
      "4h": "7",
      "8h": "14",
      "1d": "30",
      "4d": "180",
    }[timeLimit];
    const timeM = {
      "30m": 30 * 60,
      "4h": 4 * 60 * 60,
      "8h": 4 * 60 * 60,
      "1d": 4 * 60 * 60,
      "4d": 4 * 24 * 60 * 60 + 8 * 60 * 60,
    }[timeLimit];

    let res1, res2;
    res1 = await getTokenPriceByIds(token1, days, "ohlc");
    if (["binance-usd", "usd-coin", "tether", "dai"].indexOf(token2) >= 0) {
      res2 = res1.map((item) => {
        return [item[0], 1, 1, 1, 1];
      });
    } else {
      res2 = await getTokenPriceByIds(token2, days, "ohlc");
    }
    if (!res1 || !res1.length || !res2 || !res2.length) return [];

    if (timeLimit == "8h") {
      res1 = setKeyData(res1, 8);
      res2 = setKeyData(res2, 8);
    }
    if (timeLimit == "1d") {
      res1 = setKeyData(res1, 0);
      res2 = setKeyData(res2, 0);
    }

    const prices1 = res1 || [];
    const prices2 = res2 || [];
    const listMap = {};
    const decimalPlaces1 = getDecimalPlaces(prices1[0][1]);
    const decimalPlaces2 = getDecimalPlaces(prices2[0][1]);
    const decimalPlaces3 = getDecimalPlaces(
      prices1[0][1] > prices2[0][1]
        ? prices2[0][1] / prices1[0][1]
        : prices1[0][1] / prices2[0][1]
    );
    const decimalPlaces4 =
      decimalPlaces1 > decimalPlaces2 ? decimalPlaces1 : decimalPlaces2;
    const decimalPlaces =
      decimalPlaces3 > decimalPlaces4 ? decimalPlaces3 : decimalPlaces4;
    prices1.map((item) => {
      item[1] = new BigNumber(item[1]).toFixed(decimalPlaces);
      item[2] = new BigNumber(item[2]).toFixed(decimalPlaces);
      item[3] = new BigNumber(item[3]).toFixed(decimalPlaces);
      item[4] = new BigNumber(item[4]).toFixed(decimalPlaces);
      const time = item[0];
      listMap[time] = {
        time,
        value: [item[1], item[2], item[3], item[4]],
      };
    });
    prices2.map((item) => {
      item[1] = new BigNumber(item[1]).toFixed(decimalPlaces);
      item[2] = new BigNumber(item[2]).toFixed(decimalPlaces);
      item[3] = new BigNumber(item[3]).toFixed(decimalPlaces);
      item[4] = new BigNumber(item[4]).toFixed(decimalPlaces);
      const time = item[0];
      if (listMap[time]) {
        const values = listMap[time].value;
        const item0 = (values[0] / item[1]).toFixed(decimalPlaces);
        const item1 = (values[1] / item[2]).toFixed(decimalPlaces);
        const item2 = (values[2] / item[3]).toFixed(decimalPlaces);
        const item3 = (values[3] / item[4]).toFixed(decimalPlaces);
        listMap[time].value = [item0, item1, item2, item3];
      }
    });
    const result = [];
    for (var time in listMap) {
      result.push({
        time: time,
        value: listMap[time].value,
      });
    }
    let prev = null;
    return result.map((item, i) => {
      const close = +item.value[3];
      const open = prev ? +prev.close : +item.value[0];
      let high = +item.value[1];
      if (high < close) high = close;
      if (high < open) high = open;

      let low = +item.value[2];
      if (low > close) low = close;
      if (low > open) low = open;

      prev = {
        close: Number(close),
        high: Number(high),
        low: Number(low),
        open: Number(open),
        time: +item.time / 1000 + 8 * 60 * 60 - timeM,
      };
      return prev;
    });
  } catch (e) {
    console.log("v3", e);
    return [];
  }
};
