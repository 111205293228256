
export const CACHE_KEY = {
  Account: "selectedWallet",
  Wallet: "walletName",
  Chain: "chainKey",
};

export const getCache = (key, def) => {
  let item = window.localStorage.getItem(key) || def || "";
  if (item !== null && item.indexOf('expirationTime') != -1) {
    const parsedItem = JSON.parse(item);
    const now = new Date().getTime();
    if (parsedItem.expirationTime >= now) {
      return parsedItem.value;
    } else {
      window.localStorage.removeItem(key);
      return null;
    }
  }
  return item;
};

export const setCache = (key, value, expirationTime) => {
  if (expirationTime) {
    const now = new Date().getTime();
    const item = {
      value: value,
      expirationTime: now + expirationTime
    };
    window.localStorage.setItem(key, JSON.stringify(item));
  } else {
    window.localStorage.setItem(key, value);
  }
};

(() => {
  if (!window.localStorage) return;
  // check size
  var size = 0;
  for(var item in window.localStorage) {
      if(window.localStorage.hasOwnProperty(item)) {
        size += window.localStorage.getItem(item).length;
      }
  }
  const localStorageUsed = (size / 1024).toFixed(2);
  const localStorageTotal = 1024 * 5;
  console.log('localStorage used', localStorageUsed, localStorageTotal);
  if (localStorageUsed / localStorageTotal > 0.9) {
    localStorage.clear();
  }
  // check version
  const cacheVersion = 1
  let localCatchVersion = getCache('cacheVersion')
  if (cacheVersion != localCatchVersion) {
    localStorage.clear();
    setCache('cacheVersion', cacheVersion)
  }
})()

