import { getters, contract as state } from "./contract";
import { isSideChain, getNativeToken, isV2, getExchange } from "./utils/getChains";
import { swapSdk } from '@/connectWallet'
import { getTokenPriceBySymbol } from "@/api/classicV3";
import BigNumber from "bignumber.js";
import ETH from "@/assets/nft/icon-eth.svg";
import SOL from "@/assets/nft/icon-sol.svg";
import AVAX from "@/assets/nft/icon-avax.svg";
import { saveToken } from "@/utils/localCache";
import { isGnosisSafe } from "@/utils/helpers";

export default {
  computed: {
    ...getters,
    nftNativeTokenIcon () {
      if (this.chainKey === 'solana') {
        return SOL;
      } else if (this.chainKey === 'polygon') {
        return ETH;
      } else if (this.chainKey === 'avax') {
        return AVAX;
      } else if (this.chainKey === 'arbitrum') {
        return ETH;
      } else if (this.chainKey === 'optimism') {
        return ETH;
      }
      return ETH;
    },
    nftPayTokenIcon () {
      if (state.nftPaymentToken && state.nftPaymentToken.image) {
        return state.nftPaymentToken.image;
      }
      return this.nftNativeTokenIcon;
    },
    nativeToken () {
      return getNativeToken(state.chainKey)
    }
  },
  data () {
    return {
      gasPriceOptions: [],
      nativeTokenUsd: "",
    };
  },
  methods: {
    saveToken (key, token) {
      const { id, name, symbol, icon, address, decimals, customSymbol = '', customAddress = '' } = token;
      saveToken(`${this.chainKey}_${key}`, {
        id,
        name,
        symbol,
        icon,
        address,
        decimals,
        customSymbol,
        customAddress
      })
    },
    async getTokenPrice (tokenPrev, tokenNext) {
      try {
        const res = await getTokenPriceBySymbol(state.chainKey, [tokenPrev.symbol, tokenNext.symbol]);
        tokenPrev.usd = res[tokenPrev.symbol] ? res[tokenPrev.symbol].usd : tokenPrev.usd || tokenPrev.price || 0;
        tokenNext.usd = res[tokenNext.symbol] ? res[tokenNext.symbol].usd : tokenNext.usd || tokenNext.price || 0;
      } catch (e) {
        console.log("getTokenPrice", e);
      }
    },
    async getNativeTokenPrice () {
      const { symbol } = this.nativeToken;
      const res = await getTokenPriceBySymbol(state.chainKey, [symbol]);
      this.nativeTokenUsd = res[symbol] ? res[symbol].usd : 0;
    },
    toLowerCase (val, flag) {
      if (flag) {
        return val && val.toLowerCase();
      }
      return isSideChain(this.chainKey) ? val && val.toLowerCase() : val;
    },
    formatGas (base, gas) {
      if (!base) return gas;
      const { maxFeePerGas } = gas;
      return +maxFeePerGas;
    },
    formatGasLabel (base, gas) {
      const _gas = (gas && BN(gas).div(BN(10).pow(9)).toFixed(2)) || "-";
      if (!base) return Number(_gas);
      const _base = (base && BN(base).div(BN(10).pow(9)).toFixed(2)) || "-";
      return _base + "~" + _gas;
    },
    async getGasPrice () {
      if (!isV2(this.chainKey)) {
        return;
      }
      const res = await this.$axios.get(this.walletPath + "gas-price", { cache: true, expire_time: 30000 });
      const { base, fast, instant, standard } = res;
      const _fast = this.formatGas(base, fast);
      const _instant = this.formatGas(base, instant);
      const _standard = this.formatGas(base, standard);
      state.gasPriceOptions = this.gasPriceOptions = [
        {
          label:
            this.formatGasLabel(base, _standard) +
            " " +
            this.$t("gas_standard"),
          code: "Standard",
          value: _standard,
          ethgas: base ? { base, ...instant } : "",
          gwei: this.formatGasLabel(base, _standard)

        },
        {
          label:
            this.formatGasLabel(base, _fast) + " " + this.$t("gas_fast"),
          code: "Fast",
          value: _fast,
          ethgas: base ? { base, ...fast } : "",
          gwei: this.formatGasLabel(base, _fast)
        },
        {
          label:
            this.formatGasLabel(base, _instant) +
            " " +
            this.$t("gas_instant"),
          code: "Instant",
          value: _instant,
          ethgas: base ? { base, ...instant } : "",
          gwei: this.formatGasLabel(base, _instant)
        },

      ];

      if (this.chainKey === "eth") {
        const option = window.localStorage.getItem("gasoption");
        switch (option) {
          case "Standard":
          case "Fast":
            state.gasPrice = _fast;
            break;
          case "Instant":
            state.gasPrice = _instant;
            break;
          default:
            state.gasPrice =
              window.localStorage.getItem("gasprice") || _standard;
            break;
        }
      } else {
        state.gasPrice = _standard;
        if (swapSdk && swapSdk.wallet && !isGnosisSafe()) {
          let _gasPrice;
          try {
            _gasPrice = await swapSdk.wallet.sdk.eth.getGasPrice();
          } catch (e) {
            // console.log('this.wallet.sdk.eth.getGasPrice', e);
          }
          if (_gasPrice && !state.gasPrice) {
            state.gasPrice = _gasPrice;
          }
          if (_gasPrice && state.gasPrice && BigNumber(_gasPrice).comparedTo(state.gasPrice) < 0) {
            state.gasPrice = _gasPrice;
          }
          console.log('_gasPrice', _gasPrice)
          console.log('gasPrice', state.gasPrice)
        }
        if (!state.gasPrice) state.gasPrice = '5000000000'
      }
    },
    getExchange () {
      if (["onto", "solana", "starknet", "aptos", "near", "ont"].indexOf(this.chainKey) >= 0) {
        this.getBalanceOf();
        return;
      }
      this.exchange = getExchange(this.chainKey);
      this.getBalanceOf();
    },
  },
};
