import Vue from 'vue';
import View from './index.vue';

const connectWalletModal = async (params) => {
  return new Promise((resolve, reject) => {
    const Component = Vue.extend(View);
    const component = new Component({
      params,
      resolve
    }).$mount();
    document.body.appendChild(component.$el);
  });
}

export default connectWalletModal;
