<template>
  <NouDialog class="connect-view" :visible.sync="visible" @click.native="closeHandler" @leave="closeModal">
    <div class="connect-view__content" v-if="!isCustomization">
      <h2 class="g-dialog-title">
        <div class="title">
          <span>Connect Your Wallet on {{ chainInfo.label }}</span>
        </div>
        <div class="g-circle-button" @click="close()">
          <i class="iconfont iconClose icon_close"></i>
        </div>
      </h2>
      <div class="split">
        <ul class="split__chains" v-if="connectType === '01'">
          <svg viewBox="0 0 40 40" @click="isCustomization = !isCustomization">
            <rect rx="20" />
            <path d="M27 21H21V27H19V21H13V19H19V13H21V19H27V21Z" fill="var(--classic-chain-add)" />
          </svg>
          <ChainItem v-for="(item, i) in chains" :key="i" :chain-item="item" :active="item.walletValue === chainName" @select="selectChain" />
        </ul>
        <div class="split__wallets wallet-error" v-if="error">
          <div class="wallet-error__title">
            <i class="iconfont iconwarn"></i>
            <span>Could Not Connect to Your Wallet</span>
          </div>
          <div class="wallet-error__tips">
            <span>Please check if your wallet is either locked <br />or not installed.</span>
          </div>
          <div v-if="walletConnectObj" style="width:100%">
            <div class="wallet-error__button">
              <div class="btn g-clickable" @click="error = false">
                <span>Back</span>
              </div>
              <div class="btn btnP g-clickable" @click="selectWallet(walletConnectObj)">
                <span>WalletConnect</span>
              </div>
            </div>
            <div class="wallet-error__button wallet-error__button1">
              <a :href="installUrl" target="_blank" class="btn btnB g-clickable">
                <span>Install Wallet On</span>
              </a>
            </div>
          </div>
          <div v-else class="wallet-error__button">
            <div class="btn g-clickable" @click="error = false">
              <span>Back</span>
            </div>
            <a :href="installUrl" target="_blank" class="btn btnP g-clickable">
              <span>Install Wallet On</span>
            </a>
          </div>
        </div>
        <div class="split__wallets" v-else>
          <div class="sub-content">
            <div v-for="item in wallets" :key="item.name" class="item g-clickable" :class="{ pending: selectedWallet === item.name && pending }" @click="selectWallet(item)">
              <img class="item__icon" :src="item.icon" alt="" />
              <div class="item__label">{{ item.name }}</div>
              <span v-if="selectedWallet === item.name && pending">{{
    t("connect_pending")
  }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="customization" v-if="isCustomization">
      <h2 class="g-dialog-title">
        <div class="g-back-button" @click="back">
          <svg viewBox="0 0 24 24">
            <path d="M15.41 16.59L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.59Z" fill="var(--classic-back)" />
          </svg>
        </div>
        <span>{{ t("dialog_connect_customization") }}</span>
        <div class="g-circle-button" @click="close">
          <i class="iconfont iconClose icon_close"></i>
        </div>
      </h2>
      <div class="customization-content">
        <div class="row" v-for="i in chains.map((_, i) => i).filter((i) => i % 2 === 0)" :key="i">
          <div :class="!chains[i].disabled ? 'odd active' : 'odd'" @click="chains[i].disabled = !chains[i].disabled">
            <img class="item__icon" :src="chains[i].icon" alt="" />
            <div class="item__label">{{ chains[i].label }}</div>
          </div>
          <div v-if="i + 1 < chains.length" :class="!chains[i + 1].disabled ? 'even active' : 'even'" @click="chains[i + 1].disabled = !chains[i + 1].disabled">
            <img class="item__icon" :src="chains[i + 1].icon" alt="" />
            <div class="item__label">{{ chains[i + 1].label }}</div>
          </div>
        </div>
      </div>
      <div class="confirm" @click="confirm">
        <span>{{ t("dialog_confirm") }}</span>
      </div>
    </div>
  </NouDialog>
</template>

<script>
import NouDialog from "@/components/common/NouDialog";
import ChainItem from "./ChainItem";
import { tryConnect, ooeSdk } from "@/connectWallet";
import getChains, { getWalletInstallUrl } from "@/utils/getChains";

export default {
  name: "ConnectViewModal",
  components: { ChainItem, NouDialog },
  data (vm) {
    const { params, resolve } = vm.$options || {};
    const { chainKey, connectType, walletKey } = params
    return {
      chainName: chainKey,
      connectType: connectType || '02',
      resolve,

      chains: JSON.parse(window.localStorage.getItem("chains")) || getChains(),
      walletList: Object.freeze(ooeSdk.config.wallets.walletList),
      visible: walletKey ? false : true,
      selectedWallet: walletKey,
      isCustomization: false,
      pending: false,
      error: false,
      installUrl: "",
    };
  },
  mounted () {
    if (this.selectedWallet) {
      this.selectWallet({
        name: this.selectedWallet,
        key: this.selectedWallet,
        direct: true
      })
    }

  },
  computed: {
    wallets () {
      return this.getTargetWallet(this.chainName)
    },
    chainInfo () {
      const list = getChains();
      return list.find(item => {
        return item.value === this.chainName;
      });
    },
    walletConnectObj () {
      let list = this.wallets.filter(item => item.name == 'WalletConnect')
      return list.length ? list[0] : ''
    }
  },
  methods: {
    t (key) {
      return window.$t(key);
    },
    selectChain (target) {
      this.chainName = target.walletValue;
      this.error = false;
    },
    
    async selectWallet (target) {
      this.selectedWallet = target.name;
      if (!target.direct && !target.supportChains.includes(this.chainName)) {
        return;
      }
      if (this.pending) return
      this.pending = true;
      try {
        const connectObj = await tryConnect(this.chainName, target.key)
        this.close(connectObj)
      } catch (error) {
        if (error && error.message && error.message.startsWith('400')) {
          this.error = true;
          this.installUrl = getWalletInstallUrl(error.message);
        }else{
          let dd=error
          console.log('error----------')
          console.log(error)
        }
      }
      this.pending = false;
    },
    confirm () {
      window.localStorage.setItem("chains", JSON.stringify(this.chains));
      this.close()
    },
    closeHandler () {
      this.close()
    },
    closeModal () {
      this.close()
    },
    getTargetWallet (chain) {
      return this.walletList.filter((item) => {
        return item.supportChains.includes(chain) && ['Math Wallet'].indexOf(item.name) < 0;
      });
    },
    close (data) {
      this.resolve(data);
      this.visible = false
    },
    back () {
      (this.chains =
        JSON.parse(window.localStorage.getItem("chains")) || getChains()),
        (this.isCustomization = !this.isCustomization);
    },
  }
};
</script>

<style lang="scss" scoped>
.connect-view__content {
  width: 360px;
  background: var(--dialog-content);
  color: var(--color);
  max-height: 630px;

  @media (max-width: 700px) {
    width: 100%;
  }

  .g-dialog-title {
    margin-bottom: 0;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 6px;
    }

    img {
      width: 24px;
      margin: 0 6px;
    }
  }
}

.title {
  margin: 0;
  padding: 22px 23px 21px 23px;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  font-weight: 600;
  border-radius: 10px 10px 0 0;
  background: var(--dialog-title);
}

.split {
  display: flex;
}

.split__chains {
  padding: 0 0 20px 0;
  list-style: none;
  border-right: 1px solid var(--background-classic-border);
  max-height: 548px;
  box-sizing: border-box;
  overflow: auto;
  text-align: center;

  svg {
    margin-bottom: 12px;
    width: 40px;
    height: 40px;
    margin-top: 20px;
    cursor: pointer;

    rect {
      width: 40px;
      height: 40px;
      fill: var(--classic-chain-add-bg);
    }
  }

  &::-webkit-scrollbar-thumb:vertical {
    background-color: transparent;
  }
}

.split__wallets {
  flex: 1;
}

.sub-title {
  margin: 0;
  padding: 0;
  color: var(--classic-text-title);
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 24px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
}

.sub-content {
  margin-left: -12px;
  margin-right: -24px;
  padding-right: 12px;
  max-height: 460px;
  overflow: auto;
}

.item {
  cursor: pointer;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 30px;
  font-size: 16px;
  height: 60px;
  padding: 0 30px;
  background-color: var(--background-classic-token);
  margin-bottom: 15px;

  &:hover {
    background-color: var(--background-classic-token-hover);
  }
}

.item__icon {
  height: 32px;
  // background-color: #000000;
  border-radius: 16px;
}

.item__label {
  flex: 1;
  font-size: 14px;
  line-height: 19px;
  color: var(--classic-text-title);
  margin-left: 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
}

.pending {
  background-color: var(--background-classic-token-hover);
  border-color: var(--classic-item-hover);

  span {
    color: var(--classic-text-common);
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }
}

.wallet-error {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 120px 25px 25px;

  &__title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    i {
      color: var(--color);
      margin-right: 5px;
    }

    span {
      font-size: 18px;
      font-weight: 600;
      color: var(--color);
    }

    margin: 0 0 20px;
  }

  &__tips {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 70px;

    span {
      font-size: 14px;
      color: var(--classic-text-common);
      margin-bottom: 5px;
    }
  }

  &__button {
    width: 100%;
    box-sizing: border-box;

    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;

    .btn {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 0.3s ease;
      border-radius: 30px;
      background-color: var(--background-classic-token);
      text-decoration: none;
      border: 1px solid transparent;

      span {
        color: var(--classic-text-title);
      }

      &:hover {
        background-color: var(--background-classic-token-hover);
        // border-color: var(--classic-item-hover);
      }

      &.btnP {
        background: var(--color-blue);

        &:hover {
          opacity: 0.7;
        }
      }

      &.btnB {
        background: var(--background-classic-connect);

        span {
          color: var(--classic-item-hover) !important;
        }

        &:hover {
          opacity: 0.7;
          border-color: none !important;
        }
      }
    }

    a {
      color: var(--classic-text-title);
      width: 100%;
    }
  }

  &__button1 {
    margin-top: 10px;
    display: flex;
  }
}

.customization {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // width: 456px;
  background: var(--dialog-content);
  border-radius: 10px;
  color: var(--color);
  max-height: 630px;

  @media (max-width: 700px) {
    width: 100%;
    height: calc(50vh + 188px) !important;
  }

  .customization-content {
    // display: flex;
    height: 450px;
    overflow: auto;
    margin-top: 8px;

    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      height: 48px;
      padding: 8px 24px;

      .odd,
      .even {
        cursor: pointer;
        height: 100%;
        display: flex;
        background-color: var(--background-classic-token);
        border-radius: 8px;
        align-items: center;

        .item__icon {
          margin-left: 16px;
        }
      }

      .odd {
        margin-right: 8px;
      }

      .even {
        margin-left: 8px;
      }

      .active {
        background-color: var(--classic-token-item-active);
      }
    }
  }

  .confirm {
    background: var(--background-customization-token-confirm);
    border-radius: 16px;
    height: 48px;
    margin: 15px 26px;
    // margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      color: var(--classic-customization-token-confirm-text);
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
