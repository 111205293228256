import Vue from "vue";

export const stateGlobal = Vue.observable(
  {
    nowRoute: null,
    
  }
);


export const mutationsSwap = {
  async getChains () {

  },

};
